<template>
  <div class="box_iframe">
    <div class="row align-items-center">
      <div class="col-md-8" v-if="this.sizeWindow > 767">
        <p class="mb-0 topo_p">
          {{ categoryName }} /
          <strong>{{ suporte.name }}</strong>
        </p>
      </div>

      <div class="col-md-3 ml-auto">
        <button style="height: 30px"
          class="btn_default d-flex justify-content-center align-items-center"
          data-toggle="modal"
          @click.prevent="showModal(suporte.id)"
        >
          <img class="mr-2" src="@/assets/image/icones/icons8-info-24.png" />
          informações
        </button>
      </div>

      <div class="col-md-12 pt-3">
        <div class="wrap">
          <iframe
            class="frame"
            id="iframesTest"
            ref="iframeRef"
            :style="escale"
            :src="suporte.link"
            :sandbox="
              visible
                ? 'allow-scripts allow-same-origin allow-forms'
                : 'allow-scripts allow-same-origin allow-forms'
            "
          ></iframe>
        </div>
      </div>
    </div>
    <component :is="cpModal" :idService.sync="idService" />
  </div>
</template>

<script>
import icons_custom from "@/components/utils/_Icon";
import modal from "./info/modalSuporte";
import HTTP from "@/api_system";
export default {
  components: {
    icons_custom,
    modal
  },
  props: ["suporte", "categoryName", "totalPages", "list"],
  data() {
    return {
      escale: "",
      disabled: false,
      visible: false,
      cpModal:"",
      idService: "",
      sizeWindow: 0,
    };
  },
  created() {},
  mounted() {
    this.sizeWindow = $(window).width();
    if (this.suporte.hasPopup) {
      this.openPopup(this.suporte.link);
      this.visible = true;
    } else {
      this.visible = false;
    }
    this.escale = this.calcEscale();
  },
  methods: {
    openModal() {
				$("#modalSolicitarServicoEdit").modal("show");
			},
    calcEscale() {
      var scale = $(".wrap").width() / 1290;
      return `transform: scale(${scale});`;
    },
    openPopup(link) {
      var h = 450;
      var w = 900;
      var left = screen.width / 2 - w / 2;
      var top = screen.height / 2 - h / 2;

      window.open(
        `${link}`,
        "winname",
        `directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,top=${top},left=${left},width=${w},height=${h}`
      );

      window.close();
    },

    sendPedido() {
      var url;
      var json;

      if (this.printInformationId != "") {
      }
      if (this.$store.state.pedidos.pedidos != "") {
        url = `AddServicesOnOrder`;

        json = {
          orderId: this.$store.state.pedidos.pedidos.id,
          serviceId: this.suporte.id,

          listPrint: this.list
        };
      } else {
        url = `CreateOrder`;
        json = {
          serviceId: this.suporte.id,

          listPrint: this.list
        };
      }
      this.disabled = true;
      HTTP.post(
        `services/app/Order/${url}`,
        json,

        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(response => {
          this.$store.commit("set_pedido", response.data.result);
          this.$nextTick(() => {
            this.disabled = false;
            this.$router.push({ path: "/pedido" });
          });
        })
        .catch(error => {
          this.disabled = false;
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          });
        });
    },
    showModal(id) {
      this.cpModal = "modal";
      this.$nextTick(() => {
        this.idService = id;
        $("#modalSuporteView").modal("show");
      });
    }
  }
};
</script>

<style scoped>
.wrap {
  width: 100%;
  height: 60vh;
  padding: 0;
  overflow: hidden;
}
.frame {
  width: 1290px;
  height: 650px;
  border: 0;
  /* transform: scale(0.7); */
  transform-origin: 0 0;
}
.icon {
  background-color: #fefefe;
  transition: all 0.2s linear;
}
.icon:hover {
  background-color: #e0a638;
  transition: all 0.2s linear;
}
.topo_p {
  color: #ffffff;
}

.btn_default {
  background-color: #f38235;
  border: none;
}
.btn_default:hover {
  background-color: #e0a638;
}
.p_impressoes {
  color: #ffffff;
}
.emoji img {
  width: 38px;
  margin-right: 10px;
  height: 38px;
}
.emoji p {
  font-size: 10px;
  color: #ffffff;
}
.emoji {
  opacity: 60%;
}
.link_a {
  color: #ffffff;
}

@media screen and (max-width:767px){
  .wrap {
  height: 0vh;
}
.frame{
  display: none;
}
}
  
</style>
