<template>
	<div>
		<div class="modal fade" id="modalSuporteView" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-dialog-scrollable modal_custom" role="document">
				<div class="modal-content">
					<div class="modal-body px-4 py-4" :style="load ? 'height: 298px' : ''">
						<loader color="#e0a638" size="100" v-if="load" />
						<div v-else>
							<ValidationObserver ref="form" class="col-md-12" tag="form">
								<service 
									:name.sync=" superObj.name"
								 	:supplier.sync="superObj.supplier"
								 	:clerk.sync="superObj.clerk"
									:phone.sync="superObj.phone" 
									:secondaryPhone.sync="superObj.secondaryPhone"
									:email.sync="superObj.email"
								/>
							</ValidationObserver>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import loader from "@/components/utils/_Load";
	import HTTP from "@/api_system";
	import service from "./nameService";
	export default {
		components: {
			service,
			loader,
		},
		props: ["idService"],
		data() {
			return {
				load: false,
				disabled: false,
				disabledDelete: false,
				nameModal: "Adicionar Suporte",
				superObj: {
					name: "",
					suplier: "",
					clerk: "",
					phone: "",
					secondaryPhone: "",
					email: "",
					link: "",	
					hasPopup: false,
					instructions: [],
				},
			};
		},
		mounted() {
			var self = this;
			$("#modalSuporte").on("hide.bs.modal", function (e) {
				self.$parent.cpModal = "";
				self.$parent.idService = "";
			});
		},
		methods: {
			getEditService() {
				this.load = true;
				HTTP.get(
					`services/app/Suport/GetSuportForEdit?id=${this.idService}`,

					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getterToken}`,
						},
					}
				).then((response) => {
					this.load = false;
					this.superObj = [];
					this.superObj = response.data.result;
				});
			},
		},
		watch: {
			idService: function () {
				if (this.idService) {
					(this.nameModal = "Editar Suporte"), this.getEditService();
				} else {
					this.nameModal = "Adicionar Suporte";
				}
			},
		},
		computed: {},
	};
</script>

<style scoped>
.input_flex {
	width: 65px;
}

.fas {
	font-size: 20px;
}
.btn_default {
	background-color: #e0a638;
	height: 40px;
	border: none;
}
.btn_default:hover {
	background-color: #f38235;
}
.btn_default_excluir {
	background-color: #e67979;
	height: 40px;
	border: none;
}
.btn_default_excluir:hover {
	background-color: #dc3545;
}
.btn_default_disabled {
	background-color: #e3e3e3;
}
.modal_custom {
	max-width: 590px;
}

.modal-content {
	border-radius: 20px;
}

label.failed {
	color: #dc3545 !important;
}
::-webkit-scrollbar-track {
	background-color: #2474b2;
}

::-webkit-scrollbar {
	width: 5px;
	background: #2474b2;
}

::-webkit-scrollbar-thumb {
	background: #e0a638;
	border-radius: 10px;
}
</style>
