<template>
  <div class="servico" :class="load ? 'd-flex align-items-center' : ''">
    <loader color="#e0a638" size="100" v-if="load" />
    <component
      :is="cp"
      :suporte="suporte"
      :instructions="instructions"
      :categoryName="categoryName"
    />
  </div>
</template>

<script>
import systemic from "@/components/suporte/_sistemico";
import loader from "@/components/utils/_Load";

export default {
  components: {
    systemic,
    loader
  },
  data() {
    return {
      api:`${process.env.VUE_APP_ROOT_API}/api/services/app/Suport/GetSuport`,
      load: true,
      cp: "",
      suporte: "",
      instructions: "",
      categoryName: "",
    };
  },
  mounted() {
    this.getService();
  },
  watch: {
    "$route.params.id": function() {
      this.load = true;
      this.cp = "";
      this.getService();
    }
  },
  methods: {
    getService() {
      fetch(`${this.api}?id=${this.$route.params.id}`, {
        method: "GET",
        headers: {
          Authorization: `bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(response => response.json())
        .then(response => {
          this.suporte = response.result.suport;
          this.instructions = response.result.instructions;
          this.categoryName = response.result.categoryName;
          this.cp = "systemic";
          this.load = false;
        })
        .catch(response => {
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          });
        });
    }
  }
};
</script>

<style scoped>
.servico {
  background-color: #2474b2;
}
</style>
