<template>
	<div class="servico">
		<div class="container-fluid">
			<div class="row">
				<div class="welcomeMobile" v-if="this.sizeWindow <= 767" >
					<router-link to="/" class="imgWelCont">
					<img src="../../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
					</router-link>
					<div class="txtWelCont">
						<p class="mb-0 topo_p">
						{{ categoryName }} /
						<strong>{{ suporte.name }}</strong>
						</p>
					</div>
				</div>
				<div class="col-md-10 mt-5" id="frames">
					<iframe_list
						:suporte="suporte"
						:categoryName="categoryName"
						:totalPages.sync="totalPages"
						:list.sync="list"
					/>
				</div>
				<div class="col-md-2 pr-0" id="instructionCont">
					<instrucao :instructions="instructions"  />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import iframe_list from "@/components/suporte/interno/_Iframe";
  import instrucao from "@/components/suporte/interno/_Instrucao";

	export default {
		props: ["suporte", "instructions", "categoryName",],

		components: {
			iframe_list,
      		instrucao,
		},
		data() {
			return {
				totalPages: 0,
				list: [],
				sizeWindow: 0,
			};
		},
		watch: {
			list: function() {
				var total = this.list.reduce((total, item) => total + item.quantity, 0);
				this.totalPages = total;
			}
    },
		computed: {
			totalMetas() {
				return this.$store.getters.totalMetas;
			}
    },
    methods:{

    },
	mounted() {
		this.sizeWindow = $(window).width();
	},
	};
</script>

<style scoped>
.servico {
	background-color: #2474b2;
}
@media screen and (max-width:767px){
    #frames{
      margin-top: 0!important;
    } 
	.welcomeMobile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #217ABF;
    height: 40px;
    margin-bottom: 3%;
  }
  .imgWelCont{
    width: 12%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont{
    width: 86%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont p{
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .imgWelCont img{
    padding-left: 7%;
    width: 75%;
  }
  #instructionCont{
	padding-right: 15px!important;
  }
  }
  @media screen and (max-width:400px){
	.txtWelCont p{
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
  }
  }
</style>
